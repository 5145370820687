<template>
  <div
    class="avatar"
    :class="{ show: isLoaded || !imageSrc }"
    :style="{
      '--avatar-size': size,
    }"
  >
    <!-- v-if="!isLoaded && imageSrc" -->
    <ShadowLoader
      color="#D48343"
      border-radius="50%"
      :size="size"
    >
      <AppIcon :key="avatarPlaceholder" :name="avatarPlaceholder" :size="size" />
    </ShadowLoader>

    <div class="image__wrapper">
      <transition name="show">
        <FImage
          v-if="imageSrc && !isIoPlan"
          :key="imageSrc"
          :src="imageSrc"
          :is-ready-picture="isBase64Picture"
          class="profile-image"
          name="profile-image"
          @update="onLoaded"
          @load="onImageRendered"
        />

        <GlitchAvatar
          v-else-if="imageSrc && isIoPlan"
          :key="imageSrc"
          :src="imageSrc"
          size="44px"
          :is-ready-picture="isBase64Picture"
          @update="onLoaded"
          @load="onImageRendered"
        />

        <picture v-else class="profile-image">
          <AppIcon :key="avatarPlaceholder" :name="avatarPlaceholder" :size="size" @load="onImageRendered" />
        </picture>
      </transition>
    </div>

    <transition name="show">
      <PlanIcon
        v-if="['business', 'black'].includes(plan) && !isBadgeDisabled"
        :key="plan"
        class="plan__icon"
        :plan="plan"
        is-border
      />
    </transition>

    <div v-if="!isHoverDisabled && isRendered" class="bg" />
  </div>
</template>

<script>
import { computed, ref } from 'vue';

import PlanIcon from '@/components/Icon/PlanIcon.vue';

import { APP_ROLES } from '@/common/data';
import { useAdmin } from '@/composables/useAdmin';
import { useUser } from '@/composables/useUser';

import GlitchAvatar from './GlitchAvatar.vue';
import ShadowLoader from './ShadowLoader.vue';

export default {
  name: 'AppAvatar',
  components: { PlanIcon, ShadowLoader, GlitchAvatar },
  props: {
    size: {
      type: String,
      default: '44px',
    },
    src: {
      type: String,
      default: '',
    },
    isHoverDisabled: {
      type: Boolean,
      default: false,
    },
    isBadgeDisabled: {
      type: Boolean,
      default: false,
    },
    appRole: {
      type: String,
      default: null,
    },
    isGlitchAvatar: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { currentPlan, isAvatarExists, user } = useUser();
    const { role: adminRole } = useAdmin();
    const isLoaded = ref(false);

    const plan = computed(() => currentPlan.value?.title.toLowerCase());
    const isIoPlan = computed(() => user.value?.subscription?.plan === 'IO' && props.isGlitchAvatar);

    const isBase64Picture = computed(() => props.src.includes('data:image/png;base64'));

    const isSuccess = ref(true);
    const imageSrc = computed(() => isSuccess.value && props.src);

    const avatarPlaceholder = computed(() => {
      if (props.appRole === APP_ROLES.MERCHANT) {
        return 'user-default';
      }

      if (props.appRole === APP_ROLES.ADMIN) {
        return 'tie';
      }

      return ([APP_ROLES.ADMIN, APP_ROLES.SUPER_ADMIN].includes(adminRole?.value) ? 'tie' : 'user-default');
    });

    const onLoaded = (value) => {
      if (value === undefined) {
        isSuccess.value = value;
      }
    };

    const isRendered = ref(false);

    const onImageRendered = () => {
      isLoaded.value = true;
      setTimeout(() => {
        isRendered.value = true;
      }, 300);
    };

    return {
      plan,
      isIoPlan,
      isLoaded,
      isRendered,
      isAvatarExists,
      isBase64Picture,
      avatarPlaceholder,

      onLoaded,
      imageSrc,
      onImageRendered,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  height: var(--avatar-size);
  width: var(--avatar-size);
  position: relative;
  @include flex-center;

  &:hover {
    .bg {
      opacity: 1;
    }
  }

  &.show {
    .image__wrapper {
      opacity: 1;
      background: var(--color-white);
    }
  }

  .image__wrapper {
    position: absolute;
    top: 0;
    height: var(--avatar-size);
    width: var(--avatar-size);
    overflow: hidden;
    border-radius: 50%;
    opacity: 0;
    @include transition-base(opacity);
  }

  .plan__icon {
    box-sizing: border-box;
    position: absolute;
    z-index: 2;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -11px;
    top: -7px;
  }

  .bg {
    position: absolute;
    top: -4px;
    left: -4px;
    border-radius: 50%;
    z-index: -1;
    background: white;
    border: 2px solid var(--color-primary);
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    opacity: 0;
    @include transition-base(opacity);
  }

  &:deep(svg) {
    height: var(--avatar-size);
    width: var(--avatar-size);
  }
}

.profile-image {
  width: 100%;
  @include flex-center;
}
</style>
