<template>
  <div
    class="loader"
    :style="{
      '--avatar-size': size,
    }"
  >
    <div :key="color" class="bg">
      &nbsp;
    </div>
    <slot />
  </div>
</template>

<script>
import { computed } from 'vue';

import { hexToRGB } from '@/utils/functions';

export default {
  name: 'ShadowLoader',
  props: {
    color: {
      type: String,
      default: 'var(--color-black)',
    },
    borderRadius: {
      type: String,
      default: '0',
    },
    size: {
      type: String,
      default: '44px',
    },
  },
  setup(props) {
    const bg = computed(() => {
      const rgb = hexToRGB(props.color);
      return `linear-gradient(90deg,rgba(${rgb}, 0.1) 25%,rgba(${rgb}, 0.3) 37%,rgba(${rgb}, 0.1) 63%)`;
    });

    return { bg };
  },
};
</script>

<style lang="scss" scoped>
.loader {
  position: relative;

  height: var(--avatar-size);
  width: var(--avatar-size);

  .bg {
    border-radius: v-bind('borderRadius');
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    background: v-bind('bg');
    background-size: 400% 100%;
    animation: anim 1.4s ease infinite;
  }
}

@keyframes anim {
  0% { background-position: 100% 50%; }
  100% { background-position: 0 50%; }
}
</style>
